<!--  -->
<template>
  <div>
    <div class="rgh_huwf">
      <div class="eg_nuhgwf">
        <div class="eg-jhhwf">
          <div class="g_juwf"></div>
          <div class="yewupf">业务平台</div>
        </div>
        <div class="uf_huf">
          <div class="g_hygwf"></div>
          <div class="rhhuw-h"></div>
        </div>
      </div>
      <div class="erg-uhwf">
        <div class="regh_huwf"></div>
        <div class="eg_ygw">
          <div>
            <div>
              <span class="eg_hywf">车世际的</span>
              <span class="eg_bf">8大</span>
              <span class="eg_hywf">主营业务</span>
            </div>
            <div class="jihwf">
              养车 租车 新车 二手车 车辆托管 司机招募 停驶无忧 停车赚绿码
            </div>
            <div class="erg_hygf">
              <div class="eg_gfdbaf">一、车辆平台运营（车主闲置车辆）；</div>
              <div class="eg_gfdbaf">
                二、网约车平台招募，推荐介绍司机的引入B端C端的互换；
              </div>
              <div class="eg_gfdbaf">
                三、让消费者更方便出行，租车修车通过网络快速查询，同时不仅价格透明，还能随时参与商家的优惠活动；
              </div>
              <div class="eg_gfdbaf">
                四、新车、二手车推荐低价并且得到相应的报酬，有修理厂二手车市场的介入，让用车更加放心，服务更加贴心，售后更加用心，价格更加亲民；
              </div>
              <div class="eg_gfdbaf">
                五、相应政策号召停车赚绿码，可以抵扣平台相对应商品的优惠券及提现功能；
              </div>
              <div class="eg_gfdbaf">
                六、自身车辆买卖得到实质性的优惠，多商家对比，平台免费评估；
              </div>
              <div class="eg_gfdbaf">
                七、消费者自身资源变现，人脉圈层多元化，得到进一步深化，资源共享；
              </div>
              <div class="eg_gfdbaf">
                八、通过停驶无忧为自己省钱，提供安全保障。
              </div>
            </div>
          </div>
          <div>
            <img
              class="img-responsive"
              src="../../../image/pingtaiyewu.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="eg_hgygwf">Platform business</div>
    </div>
    <div class="wef_huwf">
      <div class="eg_nuhgwf-two">
        <div class="eg-jhhwf-two">
          <div class="g_juwf"></div>
          <div class="yewupf">市场动态</div>
        </div>
        <div class="regh_huwf_two">上车世际，商家好助手，老百姓好帮手！</div>
      </div>
      <div class="two-fwf">
        <div class="g_huwf">
          <img src="../../../image/backgrounImg.png" alt="" />
        </div>
        <div class="eg_hygqwf">
          <div class="he_name">成都锦泰然</div>
          <div class="e_hezuoheb">合作伙伴</div>
        </div>
        <div class="eg_hygqwf">
          <div class="he_name">美年达租车</div>
          <div class="e_hezuoheb">合作伙伴</div>
        </div>
        <div class="eg_hygqwf">
          <div class="he_name">蜀城汽车</div>
          <div class="e_hezuoheb">合作伙伴</div>
        </div>
        <div class="eg_hygqwf">
          <div class="he_name">芦山前进轮胎</div>
          <div class="e_hezuoheb">合作伙伴</div>
        </div>
      </div>
      <!--  -->
      <div class="reg_huwef">
        <swiper :options="swiperOption" class="swiper_css" ref="mySwiper">
          <swiper-slide v-for="(item, index) in list" :key="index">
            <div>
              <img :src="item.img" alt="" />
              <div class="erg_hgwf">
                {{ item.title }}
              </div>
              <div class="erg_hnwd">{{ item.time }}</div>
              <div class="erg_hnwd">
                {{ item.dev }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <img
          class="rg_huwf"
          src="../../../image/jiantou_left.png"
          alt=""
          @click="prev"
        />
        <img
          class="ergg_erg"
          src="../../../image/jiantou_right.png"
          alt=""
          @click="next"
        />
      </div>
      <div class="eg_hgygwf_two">Market Dynamic</div>
    </div>
    <div class="eg_hnwfw">
      <div class="er_huwf">
        <img class="ef_imgwd" src="../../../image/introduce_back.png" alt="" />
        <div class="jiwfe_bbef">
          <div class="g_juwf"></div>
          <div class="yewupf">车世际介绍</div>
        </div>
        <div class="er_huygwf">
          <img class="erg_huhwef" src="../../../image/bejijngwd.png" alt="" />
        </div>
        <div class="wefe_hgywf">
          <div class="eg_gygyy">
            “车世际”隶属于四川车世际科技有限公司，致力于在出行市场回归共享本质，降低乘客司机、平台各方运营成本，同时提供二手车交易、租赁、平价保养的用车服务平台，平台推出“你不开车我买单、闲置车辆来赚钱”的生态模型，倡导用车养家的理念，从共享本质出发，
            充分利用闲置资源为广大中低收入车主能够实现“用车少花钱，养车花小钱、不开车可赚钱”服务。
          </div>
        </div>
      </div>
      <div class="uwf_namf">
        <div class="erg_hygywe">
          <div class="e_uhuwgef">车世际专注于</div>
          <div class="e_uhuwgef">汽车后服务市场 回归共享本质</div>
        </div>
        <div class="e_hgygwf">
          <img
            style="width: 100%; height: 100%"
            src="../../../image/moubnad.png"
            alt=""
          />
          <div class="ikqkooq">
            <div class="eg-hgttrrqd">
              <div class="eg_huhwfg">2021</div>
              <div class="uhuwef">
                <p class="eg_uwhufwf">成立时间</p>
                <p class="eg_uwhufwf">成立于2021年</p>
              </div>
            </div>
            <div class="eg-hgttrrqd">
              <div class="eg_huhwfg">18+</div>
              <div class="uhuwef">
                <p class="eg_uwhufwf">覆盖四川</p>
                <p class="eg_uwhufwf">四川市辖区18+</p>
              </div>
            </div>
            <div class="eg-hgttrrqd">
              <div class="eg_huhwfg">600+</div>
              <div class="uhuwef">
                <p class="eg_uwhufwf">合作伙伴</p>
                <p class="eg_uwhufwf">四川合作伙伴600+</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      list: [
         {
          img: require("../../../image/yaohaoyou.png"),
          title: "邀好友，赚赏金。邀请20个好友即可提现200元现金，多邀多得。",
          time: "2020-11-23",
          dev: "车世际最新活动邀请20个好友，即可提现200元现金。最近的毛不多，赶紧动员身边的朋友一起薅羊毛吧！",
        },
        {
          img: require("../../../image/xingbanbenshang.png"),
          title: "车世际新版本上线，隆重推出车辆托管以及网约车司机招募2大特色功能",
          time: "2020-12-02",
          dev: "和车世际合作，车辆托管业务最高返佣1500元，网约车司机招募最高返佣2000元。",
        },
        {
          img: require("../../../image/xicheimg.png"),
          title: "车世际联合平台商家驰行汽车推出1元洗车活动！",
          time: "2020-12-02",
          dev: "2021年12月09日-2021年12月12日，在雅安万达广场旁的一家洗车店人声鼎沸，络绎不绝，好事连连。",
        },
      ],
      swiperOption: {
        observer: true,
        slidesPerView: 3,
        spaceBetween: 100,
        slidesOffsetBefore: 20,
        loop: true,
        autoplay: 1000,
        autoplayDisableOnInteraction: false,
        pagination: ".swiper-pagination",
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.eg-jhhwf {
  display: flex;
  align-items: center;
  height: 180px;
  justify-content: flex-end;
  position: relative;
  z-index: 10;
}
.eg-jhhwf-two {
  width: 40%;
  display: flex;
  align-items: center;
  height: 233px;
  justify-content: center;
  position: relative;
  z-index: 10;
}
.eg_nuhgwf {
  width: 20%;
}
.eg_nuhgwf-two {
  width: 100%;
  display: flex;
}
.erg-uhwf {
  width: 80%;
}
.wef_huwf {
  position: relative;
}
.g_juwf {
  width: 8px;
  height: 42px;
  background: #ff6600;
}
.regh_huwf_two {
  width: 60%;
  height: 233px;
  background: linear-gradient(135deg, #fff0e6 0%, #f6ecfc 64%, #e1f7ff 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 54px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #272727;
}
.yewupf {
  font-size: 48px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  line-height: 82px;
  color: #272727;
  padding-left: 6px;
}
.regh_huwf {
  height: 180px;
  background: linear-gradient(318deg, #fff0e6 0%, #f6ecfc 64%, #e1f7ff 100%);
}
.rgh_huwf {
  display: flex;
  position: relative;
}
.eg_hgygwf_two {
  position: absolute;
  width: 100%;
  height: 232px;
  display: flex;
  align-items: center;
  top: 0;
  left: 15%;
  font-size: 46px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 78px;
  color: #ececec;
  padding-top: 55px;
}
.eg_hgygwf {
  position: absolute;
  width: 100%;
  height: 180px;
  display: flex;
  align-items: flex-end;
  top: 0;
  left: 10%;
  font-size: 46px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 78px;
  color: #ececec;
  padding-bottom: 25px;
}
.uf_huf {
  width: 100%;
  height: 900px;
  display: flex;
  align-items: flex-end;
}
.g_hygwf {
  height: 66px;
  width: 45%;
  background: linear-gradient(135deg, #fff0e6 0%, #f6ecfc 64%, #e1f7ff 100%);
}
.rhhuw-h {
  height: 900px;
  width: 55%;
  background: linear-gradient(1deg, #ff6600 0%, #f69f07 100%);
  border: 1px solid #e9e9e9;
}
.eg_ygw {
  height: 900px;
  padding: 0 142px 0 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.eg_hywf {
  font-size: 56px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  line-height: 48px;
  color: #272727;
}
.eg_bf {
  font-size: 56px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  line-height: 48px;
  color: #ff6600;
}
.jihwf {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 48px;
  color: #272727;
}
.erg_hygf {
  padding-top: 130px;
}
.eg_gfdbaf {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 32px;
  color: #272727;
}
.two-fwf {
  width: 100%;
  height: 439px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.g_huwf {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 439px;
}
.eg_hygqwf {
  position: relative;
  width: 380px;
  height: 186px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.he_name {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 64px;
  color: #272727;
}
.e_hezuoheb {
  position: absolute;
  background: rgba(0, 0, 0, 0.26);
  border-radius: 0px 11px 11px 0px;
  top: 25px;
  left: 0;
  height: 21px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 21px;
}
.swiper_css {
  height: 534px;
  overflow: hidden;
  position: relative;
}
.reg_huwef {
  width: 100%;
  height: 641px;
  background: #ff6600;
  padding: 107px 160px 0 160px;
  cursor: pointer;
}
.rg_huwf {
  position: absolute;
  bottom: 8%;
  left: 87px;
}
.ergg_erg {
  position: absolute;
  bottom: 8%;
  right: 87px;
}
.erg_hgwf {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 36px;
  color: #ffffff;
  padding: 40px 0 30px 0;
  box-sizing: border-box;
}
.erg_hnwd {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: #ffffff;
}
.eg_hnwfw {
  display: flex;
}
.er_huwf {
  width: 50%;
  height: 1080px;
  position: relative;
}
.uwf_namf {
  width: 50%;
  height: 1080px;
  position: relative;
}
.ef_imgwd {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.jiwfe_bbef {
  width: 100%;
  padding-left: 142px;
  display: flex;
  position: relative;
  z-index: 9;
  align-items: center;
  padding-top: 105px;
}
.er_huygwf {
  position: relative;
  z-index: 9;
  float: right;
  padding-top: 142px;
}
.eg_gygyy {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 32px;
  color: #272727;
}
.wefe_hgywf {
  position: relative;
  z-index: 9;
  padding-left: 142px;
  padding-top: 60px;
  float: left;
  padding-right: 195px;
  box-sizing: border-box;
}
.erg_huhwef {
  width: 773px;
}
.e_uhuwgef {
  font-size: 48px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 78px;
  color: #272727;
}
.erg_hygywe {
  width: 100%;
  height: 329px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 142px;
  text-align: right;
}
.e_hgygwf {
  width: 100%;
  height: 699px;
  position: relative;
}
.eg_huhwfg {
  font-size: 65px;
  font-family: DIN;
  font-weight: bold;
  line-height: 64px;
  color: #ffffff;
}
.eg_uwhufwf {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}
.ikqkooq {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 80px;
   justify-content: space-around;
}
.eg-hgttrrqd {
  display: flex;
  flex-direction: column;
}
.uhuwef{
  padding-top: 15px;
}
</style>